import React, { useState } from "react";
import { ReactComponent as AngledBracket } from "./angled-bracket.svg";
import formatDate from "./formatDate";

export default function Item({ item, key }) {
  const formattedDate = formatDate(item.date);
  return (
    <div key={key} className="h-[270px] w-[200px] inline-block  pr-3 lg:pr-4">
      <div className="h-full bg-white rounded-xl overflow-hidden flex flex-col hover:scale-[102.5%] scale-transition duration-200">
        <div
          id="item-top"
          className="flex h-[45%] items-center  bg-gray-400 relative"
        >
          <AngledBracket className="absolute w-full h-full z-0"></AngledBracket>
          <img
            src="https://source.unsplash.com/random/800x600?nature"
            alt=" "
            className="z-10"
          />
        </div>
        <div
          className="transition-all h-[55%] duration-300 rounded-b-2xl group-hover:bg-gray-50 overflow-hidden p-2
        "
        >
          <div id="middle-bar" className="mt-[5px] mb-[2.5px]">
            <span className="text-indigo-600 font-medium block text-xs">
              {formattedDate}
            </span>
            <span className="text-gray-400 font-medium block text-xs">
              Downtown LA
            </span>
          </div>
          <h4 className="text-sm text-gray-900 font-bold leading-tight   line-clamp-2 whitespace-normal mb-[2.5px]">
            {item.first_sentence}
          </h4>
          <p className="text-sm text-gray-500 leading-tight whitespace-normal line-clamp-2">
            {item.remaining_text}
          </p>
          <div className="text-right mt-auto mb-0 ">
            <a
              href="javascript:;"
              className="cursor-pointer text-xs text-indigo-600 font-semibold  ml-auto mr-[2.5px]"
            >
              Learn more {`>>`}
            </a>
            {/* <LinkSvg className="text-blue-200 h-4 w-4 inline ml-2" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
