import React from "react";
import Page from "../page/Page";

const Post = () => {
  return (
    <Page>
      <div className="max-w-4xl mx-auto py-8">
        <div className="mb-8">
          <img
            src="https://source.unsplash.com/random/800x600?nature"
            alt="Post Main Image"
            className="w-full h-96 object-cover rounded-lg"
          />
        </div>

        <div className="mb-4">
          <h1 className="text-4xl font-bold">My Awesome Post</h1>
          <p className="text-gray-600">
            This is a description of my awesome post. It's a long-form article
            about something interesting and engaging. We'll explore this topic
            in-depth and provide valuable insights and perspectives.
          </p>
        </div>

        <div className="flex items-center mb-8">
          <img
            src="https://source.unsplash.com/random/100x100?face"
            alt="Author Avatar"
            className="w-10 h-10 rounded-full mr-4"
          />
          <div>
            <p className="text-lg font-semibold">John Doe</p>
            <p className="text-gray-600">Nature Photographer</p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">More Photos</h2>
          <div className="grid grid-cols-3 gap-4">
            <img
              src="https://source.unsplash.com/random/800x600?landscape"
              alt="Photo 1"
              className="w-full h-48 object-cover rounded-lg"
            />
            <img
              src="https://source.unsplash.com/random/800x600?forest"
              alt="Photo 2"
              className="w-full h-48 object-cover rounded-lg"
            />
            <img
              src="https://source.unsplash.com/random/800x600?mountain"
              alt="Photo 3"
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-bold mb-4">Comments</h2>
          <div className="space-y-4">
            <div className="bg-gray-100 p-4 rounded-lg">
              <p>Wow, stunning photos! Can't wait to read the full article.</p>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <p>
                I love the colors and compositions in these shots. Great work!
              </p>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <p>
                Where were these photos taken? I'd love to visit some of these
                locations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Post;
