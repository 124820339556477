import React from "react";
const tg = require("./lady-with-telegram.jpg");
const features = [
  {
    icon: (
      <svg
        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: "Push to deploy",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
  },
  {
    icon: (
      <svg
        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: "SSL certificates",
    description:
      "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.",
  },
  {
    icon: (
      <svg
        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
        <path
          fillRule="evenodd"
          d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z"
          clipRule="evenodd"
        />
      </svg>
    ),
    title: "Database backups",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
  },
];

export default function FeatureSection() {
  return (
    <div className="px-1">
      <div className="px-4 lg:px-12 bg-gray-50 rounded-2xl overflow-hidden py-16  relavive">
        {/* <section className="w-screen h-full absolute bg-gray-300 top-0 left-0 z-[-1]" /> */}
        <div className="mx-auto max-w-5xl ">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 place-items-center">
            <div className="">
              <div className="lg:max-w-lg">
                <h1 className="ml-3 mb-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Zemlyanika.Net
                </h1>
                <h2 className="text-xl underline font-semibold text-sky-500">
                  ↳ We Are Finding Berries In Web
                </h2>
                <p className="mt-6 text-lg leading-7 text-gray-900">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </p>
                <dl className="mt-8 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature, index) => (
                    <div key={index} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        {feature.icon}
                        {feature.title}
                        <br />
                      </dt>
                      <dd className="inline leading-4">
                        {feature.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <span className="p-8">
              <img
                src={tg}
                className="w-[48rem]  max-w-\[500px\] max-h-\[500px\] aspect-square rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
