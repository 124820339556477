import React, { useState } from "react";
import Item from "../Item/Item";

import LoadMore from "../Item/LoadMore";
import TopRatedPosts from "../Titles/TopRatedPosts";

const ItemSection = ({ title, fetchedRecentItems }) => {
  // const divs = Array.from({ length: 10 }, (_, index) => index + 1);

  return (
    <div id="item-section" className="pt-12">
      {title}
      <div className="overflow-x-scroll">
        <div
          id="item-section-row"
          className="whitespace-nowrap pl-3 xl:pl-0 pt-4 pb-6 inline-flex"
        >
          {fetchedRecentItems.map((item, index) => (
            <Item item={item} key={index} />
          ))}
          <LoadMore />
        </div>
      </div>
    </div>
  );
};

export default ItemSection;
