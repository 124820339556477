import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SideMenuProvider from "./providers/SideMenuProvider";
import { ItemsProvider } from "./providers/ItemsProvider";
import SectionRefProvider from "./providers/SectionRefProvider";
import MobileMenuProvider from "./providers/MobileMenuProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SectionRefProvider>
    <MobileMenuProvider>
      <SideMenuProvider>
        <ItemsProvider>
          <App />
        </ItemsProvider>
      </SideMenuProvider>
    </MobileMenuProvider>
  </SectionRefProvider>
);
