// ItemsContext.js
import { data } from "autoprefixer";
import React, { createContext, useState, useEffect } from "react";

export const ItemsContext = createContext();

export const ItemsProvider = ({ children }) => {
  const [fetchedRecentItems, setFetchedRecentItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/items`);
        const data = await response.json();
        const dataItems = data.items;
        console.log(data);
        if (response.ok) {
          // If the response is successful (status code in the range 200-299)
          setFetchedRecentItems(dataItems);
        } else {
          // If the response is not successful
          console.log("Server response:", data.message);
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <ItemsContext.Provider value={{ fetchedRecentItems }}>
      {children}
    </ItemsContext.Provider>
  );
};
