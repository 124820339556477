import React, { useContext, useState } from "react";
// import { SideMenuContext } from "../../providers/SideMenuProvider";
import SideMenu from "../SideMenu/SideMenu";
import { MobileMenuContext } from "../../providers/MobileMenuProvider";

export default function MobileMenu() {
  const {
    mobileMenuOpen,
    toggleMobileMenuOpen,
    mobileMenuClicked,
    setMobileMenuClicked,
  } = useContext(MobileMenuContext);

  // const { sideMenuState, setSideMenuState, toggleSideMenuState } =
  //   useContext(SideMenuContext);
  return (
    <div id="" className={`    2xl:hidden`}>
      <div
        id="mobilemenu-background"
        className={`fixed h-[100vh] w-[100vw] bg-black opacity-0 left-0 top-0 z-20 transition-opacity duration-400 ${
          mobileMenuOpen === "open" ? "opacity-35" : "hidden"
        }`}
        onClick={toggleMobileMenuOpen}
      />
      <div
        id="mobilemenu-visible-area"
        className={`fixed h-[100vh] w-[250px] left-0  bg-gray-900 top-0 z-30 p-4 pt-16 -translate-x-[290px] shadow-custom-45 shadow-custom-15 ${
          mobileMenuOpen === "open" ? "translate-x-0 animate-slide-in" : ""
        } ${
          mobileMenuOpen === "closed"
            ? "-translate-x-[290px] animate-slide-out"
            : ""
        }`}
      >
        <SideMenu />
      </div>
    </div>
  );
}
