import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { SectionRefContext } from "../providers/SectionRefProvider";
import { MobileMenuContext } from "../providers/MobileMenuProvider";

const Header = () => {
  const { toggleMobileMenuOpen } = useContext(MobileMenuContext);
  const { isHeaderVisible, headerRef } = useContext(SectionRefContext);

  return (
    <header
      className={`fixed top-0 left-0 w-full h-[95px] bg-gray-900 text-white h-20 transition-transform duration-300 z-[20] ease ${
        isHeaderVisible ? "translate-y-0" : "-translate-y-full"
      }`}
      ref={headerRef}
    >
      <div className="max-w-6xl w-full h-full mx-auto flex justify-start items-center">
        <div
          class="h-20 w-20 flex items-center justify-center cursor-pointer 2xl:hidden"
          onClick={toggleMobileMenuOpen}
        >
          <FontAwesomeIcon
            icon={faBars}
            className="text-blue-0 text-3xl h-8 w-8"
          />{" "}
        </div>
        <h1 className="text-2xl font-bold min-w-0 pl-8">Zemlyanika.Net</h1>
      </div>
    </header>
  );
};

export default Header;
