import React, { createContext, useState } from "react";
export const SideMenuContext = createContext();

const SideMenuProvider = ({ children }) => {
  const [sideMenuState, setSideMenuState] = useState("hidden");

  const toggleSideMenuState = () => {
    if (sideMenuState === "hidden") {
      setSideMenuState("Block");
    } else {
      setSideMenuState("hidden");
    }
  };
  return (
    <SideMenuContext.Provider
      value={{ sideMenuState, setSideMenuState, toggleSideMenuState }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};

export default SideMenuProvider;
