import React, { useState } from "react";

const options = [
  "Housing",
  "Car Rentals",
  "Health & Beauty",
  "Job Offers",
  "Finance & Crypto",
  "More",
];

const Dropdown = ({ titleClassNames }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-72">
      <div>
        <button
          type="button"
          className={`inline-flex justify-between w-full px-5 py-2 ml-4 font-medium text-black border border-4 border-black rounded-full shadow-sm hover:bg-gray-100 focus:outline-none ${titleClassNames}`}
          onClick={toggleDropdown}
        >
          <h4 className={`text-lg font-extrabold`}>
            {selectedOption || "Select Category:"}
          </h4>
          <svg
            className="-mr-1 ml-2 h-5 w-5 my-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute left-3 mt-3 w-80 rounded-2xl shadow-lg bg-gray-100 focus:ring-0 z-20">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => (
              <button
                key={option}
                className="block px-4 py-3 text-md font-black text-gray-500 hover:bg-gray-100 hover:text-black w-full text-left"
                role="menuitem"
                onClick={() => selectOption(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
