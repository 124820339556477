import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu/MobileMenu";
import SideBar from "../components/SideBar";
import LeftPanel from "../components/LeftPanel/LeftPanel";

export default function Page({ children }) {
  return (
    <div id="page" className="pt-[90px] relative bg-gray-300">
      <LeftPanel />
      <MobileMenu />
      {/* <SideBar /> */}
      <Header />
      {children}
      <Footer />
    </div>
  );
}
