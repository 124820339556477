import React from "react";
import { ReactComponent as Carousel } from "./carousel.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";

export default function LoadMore() {
  return (
    <div className="h-[270px] w-[200px] inline-block  pr-3 lg:pr-4">
      <div className="h-full bg-white bg-opacity-25 rounded-xl overflow-hidden flex flex-col hover:scale-[102.5%] scale-transition duration-200">
        <div className="h-[50%] flex flex-col items-center justify-center cursor-pointer">
          <Carousel className="w-16 h-16 opacity-[67.5%]" />
          <h4 className="underline text-sm">Load more {`>`}</h4>
        </div>
        <div className="h-[50%] flex flex-col items-center justify-center cursor-pointer">
          <h4 className="underline text-sm">Open Category {`>`}</h4>
          <Dashboard className="w-16 h-16 opacity-[67.5%] p-1 mt-1" />
        </div>
      </div>
    </div>
  );
}
