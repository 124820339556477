import React from "react";
import RealSideMenu from "../SideMenu/SideMenu";

export default function LeftPanel() {
  return (
    <div
      id="leftpanel"
      className="fixed left-0 top-0 h-screen min-w-[240px] calc-width text-white bg-gray-900 pt-[70px] px-3 border-r-2 border-gray-600 overflow-y-scroll hidden 2xl:block"
    >
      <div id="leftpanel-rel"></div>
      <RealSideMenu />
    </div>
  );
}
