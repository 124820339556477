import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  //   useContext,
} from "react";
// import { HeaderHoverContext } from "./HeaderHoverProvider";

export const SectionRefContext = createContext();

const SectionRefProvider = ({ children }) => {
  //   const [trigger, setTrigger] = useState(false);
  //   const { setHeaderHover } = useContext(HeaderHoverContext);
  const sectionRefs = useRef([]);
  const headerRef = useRef(null);
  const competitionsRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const prevScrollPosRef = useRef(window.pageYOffset);

  const scrollToSection = (sectionRef) => {
    const section = sectionRef.current;

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSectionRef = (ref, index) => {
    if (ref) {
      sectionRefs.current[index] = ref;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const header = headerRef.current;

      if (header) {
        const headerHeight = header.offsetHeight;

        if (prevScrollPosRef.current > currentScrollPos) {
          // Прокрутка вверх, показать хедер
          setIsHeaderVisible(true);
        } else if (
          prevScrollPosRef.current < currentScrollPos &&
          currentScrollPos > headerHeight + 50
        ) {
          setIsHeaderVisible(false);
          //   setHeaderHover(false);
        } else {
          setIsHeaderVisible(true);
        }

        prevScrollPosRef.current = currentScrollPos;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sectionRefs.current.length > 0) {
      console.log(sectionRefs);
    }
  }, [sectionRefs]);

  return (
    <SectionRefContext.Provider
      value={{
        sectionRefs,
        headerRef,
        competitionsRef,
        isHeaderVisible,
        setIsHeaderVisible,
        prevScrollPosRef,
        scrollToSection,
        handleSectionRef,
      }}
    >
      {children}
    </SectionRefContext.Provider>
  );
};

export default SectionRefProvider;
