import React from "react";

const blogItems = [
  {
    id: 1,
    title: "Boost your conversion rate",
    description:
      "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.",
    imageUrl:
      "https://tailwindui.com/img/ecommerce-images/blog-01-product-shot.jpg",
    author: {
      name: "Roel Aufderehar",
      imageUrl:
        "https://tailwindui.com/img/ecommerce-images/blog-author-01.jpg",
    },
  },
  // Add more blog items as needed
];

export default function BlogSection() {
  return (
    <section className="py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-16">
          Our latest blog
        </h2>
        {/* {blogItems.map((item) => (
          <BlogItem key={item.id} item={item} />
        ))} */}
        <div className="flex justify-center  gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8 outline outline-white">
          <div className="group w-full max-lg:max-w-xl lg:w-1/3 border borde-gray-300r bg-white rounded-2xl">
            <div className="flex items-center min-h-[40%] bg-gray-300">
              <img
                src="https://pagedone.io/asset/uploads/1696244317.png"
                alt="blogs tailwind section"
                className="rounded-t-2xl w-full"
              />
            </div>
            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
              <span className="text-indigo-600 font-medium mb-3 block">
                Jan 01, 2023
              </span>
              <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                Clever ways to invest in product to organize your portfolio
              </h4>
              <p className="text-gray-500 leading-6 mb-10">
                Discover smart investment strategies to streamline and organize
                your portfolio..
              </p>
              <a
                href="javascript:;"
                className="cursor-pointer text-lg text-indigo-600 font-semibold"
              >
                Read more..
              </a>
            </div>
          </div>
          <div className="group w-full max-lg:max-w-xl lg:w-1/3 border borde-gray-300r bg-white rounded-2xl">
            <div className="flex items-center min-h-[40%] bg-gray-300">
              <img
                src="https://pagedone.io/asset/uploads/1696244317.png"
                alt="blogs tailwind section"
                className="rounded-t-2xl w-full"
              />
            </div>
            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
              <span className="text-indigo-600 font-medium mb-3 block">
                Jan 01, 2023
              </span>
              <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                Clever ways to invest in product to organize your portfolio
              </h4>
              <p className="text-gray-500 leading-6 mb-10">
                Discover smart investment strategies to streamline and organize
                your portfolio..
              </p>
              <a
                href="javascript:;"
                className="cursor-pointer text-lg text-indigo-600 font-semibold"
              >
                Read more..
              </a>
            </div>
          </div>
          <div className="group w-full max-lg:max-w-xl lg:w-1/3 border borde-gray-300r bg-white rounded-2xl">
            <div className="flex items-center min-h-[40%] bg-gray-300">
              <img
                src="https://pagedone.io/asset/uploads/1696244317.png"
                alt="blogs tailwind section"
                className="rounded-t-2xl w-full"
              />
            </div>
            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
              <span className="text-indigo-600 font-medium mb-3 block">
                Jan 01, 2023
              </span>
              <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                Clever ways to invest in product to organize your portfolio
              </h4>
              <p className="text-gray-500 leading-6 mb-10">
                Discover smart investment strategies to streamline and organize
                your portfolio..
              </p>
              <a
                href="javascript:;"
                className="cursor-pointer text-lg text-indigo-600 font-semibold"
              >
                Read more..
              </a>
            </div>
          </div>
          <div className="group w-full max-lg:max-w-xl lg:w-1/3 border borde-gray-300r bg-white rounded-2xl">
            <div className="flex items-center min-h-[40%] bg-gray-300">
              <img
                src="https://pagedone.io/asset/uploads/1696244317.png"
                alt="blogs tailwind section"
                className="rounded-t-2xl w-full"
              />
            </div>
            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
              <span className="text-indigo-600 font-medium mb-3 block">
                Jan 01, 2023
              </span>
              <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                Clever ways to invest in product to organize your portfolio
              </h4>
              <p className="text-gray-500 leading-6 mb-10">
                Discover smart investment strategies to streamline and organize
                your portfolio..
              </p>
              <a
                href="javascript:;"
                className="cursor-pointer text-lg text-indigo-600 font-semibold"
              >
                Read more..
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const BlogItem = ({ item }) => {
  const { title, description, imageUrl, author } = item;

  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <img className="h-48 w-full object-cover" src={imageUrl} alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-indigo-600">
            <a href="#" className="hover:underline">
              Blog
            </a>
          </p>
          <a href="#" className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            <p className="mt-3 text-base text-gray-500">{description}</p>
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <a href="#">
              <img
                className="h-10 w-10 rounded-full"
                src={author.imageUrl}
                alt={author.name}
              />
            </a>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              <a href="#" className="hover:underline">
                {author.name}
              </a>
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime="2022-02-12">Feb 12, 2022</time>
              <span aria-hidden="true">&middot;</span>
              <span>11 min read</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
