import React, { useState } from "react";
import { ReactComponent as Template } from "./template.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as Fire } from "./fire.svg";
import { ReactComponent as Contract } from "./contract.svg";
import { ReactComponent as Hand } from "./hand.svg";
import { ReactComponent as Marketing } from "./marketing.svg";
import { ReactComponent as Partnership } from "./partnership.svg";
import { ReactComponent as AngleDown } from "./angle-down.svg";

// const sideMenu = ["Home", "Categories", "News", "Crypto", "More"];
const menuLinks = [
  { name: "Main Page", link: "/home", icon: Template },
  { name: "Hot Spot", link: "/deals", icon: Fire },
  {
    name: "Categories",
    link: "/policy",
    icon1: AngleDown,
    dropdown: [
      { name: "Housing", link: "/home", icon: Home },
      { name: "Car Rentals", link: "/home", icon: Home },
      { name: "Finance & Crypto", link: "/home", icon: Home },
      { name: "More", link: "/home", icon: Home },
    ],
  },
  { name: "Marketing Solutions", link: "/policy", icon: Marketing },
  { name: "Terms & Policy", link: "/policy", icon: Contract },
  { name: "Partnership", link: "/about", icon: Hand },
  { name: "Partnership", link: "/policy", icon: Partnership },
];

export default function SideMenu() {
  const [dropdownOpened, setDropdownOpened] = useState(true);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  return (
    <div
      id="visible-sidemenu-without-padding"
      className="w-full max-w-[240px] ml-auto mr-0  h-full mx-auto flex flex-col items-center justify-start relative"
    >
      {/* <div
      id="visible-sidemenu-cross-abs"
      className="absolute right-0 w-8 h-8 outline translate-x-3 -translate-y-3 flex items-center justify-center text-white cursor-pointer"
      onClick={() => {
        console.log("clicked");
        setSideMenuState("hidden");
      }}
    >
      X
    </div> */}
      <h3
        id="sidemenu-title"
        className="text-xl text-white font-semibold mb-12"
      >
        Side Menu
      </h3>
      {menuLinks.map((item, index) => (
        <>
          <div
            key={index}
            className="text-white w-full h-8 border-2 border-gray-600 rounded-xl flex items-center justify-between py-6 px-4 mb-2 hover:bg-gray-700 transition-colors duration-200 cursor-pointer"
            onClick={() =>
              item.dropdown &&
              setOpenDropdownIndex(openDropdownIndex === index ? null : index)
            }
          >
            <span className="flex items-center">
              {item.icon &&
                React.createElement(item.icon, {
                  className: "w-6 h-6",
                })}
              <h4 className={`${item.icon ? "pl-3" : ""}`}>{item.name}</h4>
            </span>
            {item.icon1 && (
              <div
                className={`transition-transform duration-300 ${
                  openDropdownIndex === index ? "rotate-180" : ""
                }`}
              >
                {React.createElement(item.icon1, { className: "w-6 h-6" })}
              </div>
            )}
          </div>
          {item.dropdown && (
            <Dropdown
              item={item}
              openDropdownIndex={openDropdownIndex}
              currentIndex={index}
              onClick={() =>
                setOpenDropdownIndex(openDropdownIndex === index ? null : index)
              }
            />
          )}
        </>
      ))}
      <div className="outline w-full h-20 bg-gray-200 mt-auto mb-0 flex items-center justify-evenly px-2 rounded-md">
        <div className="w-12 h-12 bg-black rounded-full" />
        <h4 className="w-auto text-center">Link 🔗 Telegram</h4>
      </div>
    </div>
  );
}

const Dropdown = ({ item, openDropdownIndex, currentIndex, onClick }) => {
  const isOpen = openDropdownIndex === currentIndex;
  return (
    <div
      className={`w-full pl-6 opacity-0 max-h-0 transition-all duration-300 ${
        isOpen === true ? "max-h-full opacity-100" : ""
      }`}
    >
      {item.dropdown.map((dItem, dIndex) => (
        <div
          key={dIndex}
          className="text-white w-full h-8 border-2 border-gray-600 rounded-xl flex items-center justify-between py-6 px-4 mb-2 hover:bg-gray-700 transition-colors duration-200 ease cursor-pointer"
        >
          {dItem.name}
        </div>
      ))}
    </div>
  );
};
