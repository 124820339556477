import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import required modules
import { Autoplay, Pagination, Navigation, Grid } from "swiper/modules";

const slides = [
  {
    id: 1,
    title: "Nice content!",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    title: "Website Wow",
    content:
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    id: 3,
    title: "Straight to the Top!",
    content:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    id: 4,
    title: "Slide 4",
    content:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
];

export default function MySwiper() {
  return (
    <div className="max-w-5xl w-full mx-auto flex items-center">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="${className} w-3 h-3 bg-indigo-900 rounded-full opacity-20"></span>`;
          },
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        loop={true} // Добавляем свойство loop со значением true
        loopFillGroupWithBlank={true}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id} className="h-full">
            <div className=" aspect-video bg-gray-300 rounded-2xl p-8 flex flex-col justify-center items-center">
              <h2 className="text-3xl font-bold text-indigo-600 mb-4">
                {slide.title}
              </h2>
              <p className="text-lg text-indigo-600">{slide.content}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
