import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white h-20 ">
      <div className="max-w-screen-2xl w-full h-full mx-auto flex justify-center items-center">
        <h1 className="text-2xl font-bold min-w-0">Zemlyanika.Net</h1>
      </div>
    </footer>
  );
};

export default Footer;
