import React, { createContext, useState } from "react";
export const MobileMenuContext = createContext();
const MobileMenuProvider = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState("");
  const [mobileMenuClicked, setMobileMenuClicked] = useState("");
  const toggleMobileMenuOpen = () => {
    if (mobileMenuOpen !== "open") {
      setMobileMenuOpen("open");
    } else {
      setMobileMenuOpen("closed");
    }
  };

  const handleBackgroundClick = () => {
    toggleMobileMenuOpen();
  };

  // useState(() => {
  //   setTimeout(() => {
  //     setMobileMenuOpen("closed");
  //   }, 0.25);
  // }, []);

  return (
    <MobileMenuContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        toggleMobileMenuOpen,
        handleBackgroundClick,
        mobileMenuClicked,
        setMobileMenuClicked,
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};

export default MobileMenuProvider;
