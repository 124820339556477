import React, { useContext, useState } from "react";

import ItemSection from "../components/ItemSection/ItemSection";
import MySwiper from "../components/MySwiper";
import ItemSectionWithDropdown from "../components/ItemSectionWithDropdown";
import AddPost from "../components/AddPost";
import AddPost2 from "../components/AddPost2";
import BlogSection from "../components/BlogSection";
import FeatureSection from "../components/FeatureSection";
import Page from "../page/Page";
import TopRatedPosts from "../components/Titles/TopRatedPosts";
import Dropdown from "../components/Dropdown";
import RecentPosts from "../components/Titles/RecentPosts";
import { ItemsContext } from "../providers/ItemsProvider";

export default function Home() {
  const { fetchedRecentItems } = useContext(ItemsContext);
  return (
    <Page>
      <div
        id="main-content-container-1280"
        className="w-full max-w-5xl flex flex-col mx-auto"
      >
        <MySwiper />
        <FeatureSection />
        {/* <AddPost />
        <BlogSection />
        <AddPost2 /> */}
        <ItemSection
          title={<TopRatedPosts />}
          fetchedRecentItems={fetchedRecentItems}
        />
        <ItemSection
          title={<RecentPosts />}
          fetchedRecentItems={fetchedRecentItems}
        />
        <ItemSection
          title={<Dropdown />}
          fetchedRecentItems={fetchedRecentItems}
        />
        {/* <ItemSectionWithDropdown /> */}
      </div>
    </Page>
  );
}
